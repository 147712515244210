<template>
    <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
        <!-- Header -->
        <HeaderLogin />
        <BtnBack />

        <!-- Title -->
        <div class="flex flex-col items-center justify-center mb-4">
            <h4 class="text-lg text-white font-bold text-center">
                ทำภารกิจรับเครดิตเพิ่ม
            </h4>
            <p v-if="userCredit !== null"
                class="bg-yellow-100 border border-yellow-400 text-yellow-700 rounded-lg px-4 py-2 shadow-md font-semibold text-base mt-2">
                เครดิตของคุณ: <span class="text-red-500 font-bold">{{ userCredit }}</span> เครดิต
            </p>
        </div>
        <!-- Task List -->
        <div v-for="(task, index) in tasks" :key="task._id"
            class="bg-gradient-to-br from-[#172f82] to-[#180650] p-4 rounded-lg shadow-lg mb-4 border border-white">
            <div class="flex justify-between items-center">
                <div>
                    <h5 class="text-white font-bold">
                        {{ task.title }}
                        <span class="text-yellow-400 text-sm font-normal ml-2">(เครดิต: {{ task.credit || 0 }})</span>
                    </h5>

                    <!-- แสดง description เป็น list -->
                    <ol class="list-decimal text-sm text-gray-300 pl-6">
                        <li v-for="(step, idx) in task.description" :key="idx">
                            {{ step }}
                        </li>
                    </ol>
                    <p v-if="task.status" class="text-yellow-400 text-sm mt-1">
                        สถานะ: {{ getStatusText(task.status) }}
                    </p>
                </div>

                <!-- ปุ่มเริ่ม -->
                <a v-if="!task.isStarted && task.status === 'new'" :href="task.link" target="_blank"
                    @click="startTask(index)"
                    class="bg-yellow-500 text-black px-4 py-2 rounded-lg font-bold shadow-lg hover:bg-yellow-600 transition-all">
                    เริ่ม
                </a>

                <!-- ปุ่ม "รออนุมัติ" และ "ทำเสร็จแล้ว" -->
                <button v-if="task.status === 'pending' || task.status === 'approved'"
                    class="bg-gray-500 text-white px-4 py-2 rounded-lg font-bold shadow-lg cursor-not-allowed" disabled>
                    {{ task.status === 'pending' ? 'รออนุมัติ' : 'ทำเสร็จแล้ว' }}
                </button>

                <!-- ปุ่มเริ่มใหม่ สำหรับสถานะ rejected -->
                <button v-if="task.status === 'rejected'" @click="restartTask(index)"
                    class="bg-yellow-500 text-white px-4 py-2 rounded-lg font-bold shadow-lg hover:bg-red-600 transition-all">
                    เริ่มใหม่
                </button>
            </div>

            <!-- Upload Section -->
            <div v-if="task.isStarted" class="mt-4">
                <div class="file-input-wrapper flex items-center space-x-2 mb-2">
                    <label :for="'file-upload-' + index"
                        class="bg-yellow-100 text-black px-4 py-2 rounded-lg font-bold shadow-lg hover:bg-yellow-600 transition-all cursor-pointer flex items-center space-x-2">
                        <span class="material-icons text-base">upload</span>
                        <span>อัพโหลดรูปภารกิจ</span>
                    </label>
                    <span class="text-white text-sm" v-if="task.uploadedFileName">{{ task.uploadedFileName }}</span>
                    <span class="text-gray-400 text-sm" v-else>ไม่ได้เลือกไฟล์ใด</span>
                    <input :id="'file-upload-' + index" type="file" class="hidden"
                        @change="handleFileUpload($event, index)" />
                </div>
                <button @click="submitTask(index)" :disabled="!task.uploadedImage"
                    class="bg-green-500 text-white px-4 py-2 rounded-lg font-bold shadow-lg hover:bg-green-600 transition-all">
                    ส่งภารกิจ
                </button>
            </div>
        </div>
        <div class="bg-yellow-50 border border-yellow-400 text-yellow-800 rounded-lg p-4 shadow-md mt-4">
            <h3 class="font-bold text-lg">⚠️ เงื่อนไข: ทำภารกิจรับเครดิตเพิ่ม</h3>
            <ol class="list-decimal list-inside mt-2 text-base">
                <li>ทำภารกิจให้ยอดครบ <span class="font-semibold">50 เครดิต</span>.</li>
                <li>เมื่อยอดถึงแล้ว <span class="font-semibold">แคปหน้าจอนี้</span> พร้อมแจ้ง <span
                        class="font-semibold">Username</span> เพื่อให้แอดมินตรวจสอบ.</li>
            </ol>
            <div class="flex justify-end mt-4">
                <a href="https://line.me/R/ti/p/@091rlmwa?oat_content=url&ts=10180109" target="_blank"
                    class="bg-green-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-green-700 shadow">
                    ติดต่อแอดมิน
                </a>
            </div>
        </div>

        <!-- Toast Notification -->
        <div v-if="toastMessage" class="toast-message">
            {{ toastMessage }}
        </div>
    </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import BtnBack from "@/components/BtnBack.vue";
import { mapState } from "vuex";
import axios from "axios";
import apiPath from "@/config/apiPath";

export default {
    name: "CreditApp",
    components: {
        HeaderLogin,
        BtnBack,
    },
    data() {
        return {
            tasks: [], // ภารกิจทั้งหมด
            userCredit: 0, // เครดิตของผู้ใช้
            toastMessage: "",
        };
    },
    computed: {
        ...mapState({
            username: (state) => state.user.username,
            phoneNumber: (state) => state.user.phoneNumber,
        }),
    },
    async mounted() {
        try {
            // ดึงข้อมูลภารกิจ
            const missionResponse = await axios.get(apiPath.GET_MISSIONS, {
                params: { username: this.username },
            });

            // console.log("Missions API Response:", missionResponse.data);

            // ดึงข้อมูล mission logs
            const creditResponse = await axios.get(apiPath.GET_MISSION_USER, {
                params: { username: this.username },
            });

            // console.log("User Credit API Response:", creditResponse.data);

            const missionLogs = creditResponse.data?.missionLogs || [];
            const missionStatuses = {};

            // คัดกรองข้อมูลให้ได้ status ล่าสุดที่มี status = approved
            missionLogs.forEach((log) => {
                const missionId = log.missionId._id || log.missionId;
                if (
                    !missionStatuses[missionId] ||
                    new Date(log.updatedAt) > new Date(missionStatuses[missionId].updatedAt)
                ) {
                    missionStatuses[missionId] = log;
                }
            });

            // console.log("Filtered Mission Statuses:", missionStatuses);

            // อัปเดต tasks
            this.tasks = missionResponse.data.missions.map((mission) => {
                const missionLog = missionStatuses[mission._id];
                return {
                    ...mission,
                    isStarted: false,
                    uploadedImage: null,
                    status: missionLog?.status || "new",
                };
            });

            // คำนวณเครดิตทั้งหมด
            this.userCredit = missionLogs
                .filter((log) => log.status === "approved")
                .reduce((sum, log) => sum + (log.credit || 0), 0);

            // console.log("Updated tasks:", this.tasks);
            // console.log("Updated userCredit:", this.userCredit);
        } catch (error) {
            // console.error("Error fetching data:", error.response?.data || error.message);
            this.showToast("ไม่สามารถโหลดข้อมูลได้");
        }
    },
    methods: {
        startTask(index) {
            this.tasks[index].isStarted = true;
            console.log(`Task ${this.tasks[index].title} started`); // ตรวจสอบว่าฟังก์ชันนี้ทำงานถูกต้อง
        },
        restartTask(index) {
            this.tasks[index].isStarted = false;
            this.tasks[index].uploadedImage = null;
            this.tasks[index].status = "new";
            // console.log(`Task ${this.tasks[index].title} restarted`);
        },
        async fetchUserCredit() {
            try {
                const creditResponse = await axios.get(apiPath.GET_MISSION_USER, {
                    params: { username: this.username },
                });
                // console.log("User Credit API Response:", creditResponse.data);

                // ตรวจสอบว่ามี missionLogs และรวมเครดิต
                const missionLogs = creditResponse.data?.missionLogs || [];
                const totalCredit = missionLogs.reduce((sum, log) => sum + (log.credit || 0), 0);
                // console.log("Extracted Total Credit:", totalCredit);

                // อัปเดต userCredit
                this.userCredit = totalCredit;
                // console.log("Updated userCredit:", this.userCredit);
            } catch (error) {
                // console.error("Error fetching user credit:", error.response?.data || error.message);
                this.showToast("ไม่สามารถโหลดข้อมูลเครดิตได้");
            }
        },
        handleFileUpload(event, index) {
            const file = event.target.files[0];
            if (file) {
                this.tasks[index].uploadedImage = file; // เก็บไฟล์
                this.tasks[index].uploadedFileName = file.name; // เก็บชื่อไฟล์
                // console.log("File uploaded:", file.name); // ตรวจสอบไฟล์ที่อัปโหลด
            } else {
                this.tasks[index].uploadedImage = null;
                this.tasks[index].uploadedFileName = ""; // ล้างชื่อไฟล์
            }
        },
        async submitTask(index) {
            const task = this.tasks[index];
            const formData = new FormData();
            formData.append("username", this.username);
            formData.append("phoneNumber", this.phoneNumber);
            formData.append("missionId", task._id); // เปลี่ยนจาก taskId เป็น missionId
            formData.append("file", task.uploadedImage);

            // console.log("Payload being sent:", [...formData.entries()]); // ตรวจสอบ payload ที่ส่งไปยัง API

            try {
                const response = await axios.post(apiPath.MAKE_MISSION, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                // console.log("Submit Task Response:", response.data); // ตรวจสอบ response ที่ได้จาก API
                this.tasks[index].isStarted = false;
                this.tasks[index].uploadedImage = null;
                this.tasks[index].status = "pending"; // อัปเดตสถานะเป็นรออนุมัติ
                this.showToast("ส่งภารกิจสำเร็จ!");
            } catch (error) {
                // console.error("Error submitting task:", error.response?.data || error.message);
                this.showToast("เกิดข้อผิดพลาดในการส่งภารกิจ");
            }
        },
        getStatusText(status) {
            switch (status) {
                case "new":
                    return "ยังไม่ได้ทำ";
                case "pending":
                    return "รออนุมัติ";
                case "approved":
                    return "ทำเสร็จแล้ว";
                case "rejected":
                    return "ถูกปฏิเสธ";
                default:
                    return "ไม่ทราบสถานะ";
            }
        },
        showToast(message) {
            this.toastMessage = message;
            setTimeout(() => {
                this.toastMessage = "";
            }, 3000);
        },
    },
};
</script>

<style scoped>
.toast-message {
    position: fixed;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffee00ee;
    color: rgb(0, 0, 0);
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}
</style>